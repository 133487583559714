<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h3 class="card-label font-weight-bolder">{{ $t('NotificationSettings.Settings') }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="card card-custom">
          <div class="card-body">
            <!--begin::Item-->
            <div class="d-flex align-items-center bg-light-light rounded p-5 gutter-b">
              <font-awesome-icon icon="user-edit" class="icon-md mr-2"/>
              <div class="d-flex flex-column flex-grow-1 mr-2 mt-1">
                <router-link
                    to="/profile-settings"
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a :href="href" @click="navigate"
                     class="font-weight-normal text-dark-75 text-hover-primary font-size-lg">{{
                      $t('NotificationSettings.Profile')
                    }}</a>
                </router-link>
              </div>
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex align-items-center bg-light-light rounded p-5 gutter-b">
              <font-awesome-icon icon="bell" class="icon-md mr-2"/>
              <div class="d-flex flex-column flex-grow-1 mr-2 mt-1">
                <router-link
                    to="/notification-settings"
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a :href="href" @click="navigate"
                     class="font-weight-bold text-primary text-hover-primary font-size-lg">{{
                      $t('NotificationSettings.Notifications')
                    }}</a>
                </router-link>
              </div>
            </div>
            <!--end::Item-->
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label font-weight-bolder">{{ $t('NotificationSettings.Notifications') }}</h3>
            </div>
          </div>

          <div v-if="!loaded" class="container py-20">
            <PuSkeleton width="100%"></PuSkeleton>
          </div>
          <div v-if="loaded" class="card-body">
            <div v-if="error_message === true" class="alert alert-danger" role="alert">{{ $t('NotificationSettings.Error') }}</div>
            <div v-else-if="error_message" class="alert alert-warning" role="alert">{{ error_message }}</div>
            <table class="table ">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" v-for="target in matrix.targets" :key="target.id">
                    {{ target.title }}
                  </th>
                </tr>
                </thead>
              <tbody>
              <tr v-for="category in matrix.categories" :key="category.id">
                <th scope="row">
                  {{ category.title }}
                  <p class="text-muted mb-0">{{ category.description }}</p>
                </th>
                <td v-for="target in matrix.targets" :key="target.id">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" :id="category.id + '-' + target.id"
                           v-model="settings[assoc_settings[category.id + '-' + target.id]]">
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <button type="button" @click="save" class="btn btn-success" :disabled="saved_indicator">
              <span v-if="saved_indicator" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ $t('NotificationSettings.Save') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="show_messages" size="md" :hide-header="true" ok-only @ok="show_messages=false"
             :ok-title="$t('NotificationSettings.close')">
      <div v-if="!error_message" class="d-flex flex-column align-items-center">
        <div class="symbol symbol-50 symbol-light-success mb-4 flex-shrink-0">
          <div class="symbol-label">
            <font-awesome-icon icon="check" class="text-success icon-lg"/>
          </div>
        </div>
        <div class="font-weight-bolder">{{ $t('NotificationSettings.message-success') }}</div>
      </div>
      <div v-else class="d-flex flex-column align-items-center">
        <div class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
          <div class="symbol-label">
            <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
          </div>
        </div>
        <div class="font-weight-bolder">{{ $t('NotificationSettings.message-error') }}</div>
      </div>
    </b-modal>
  </div>
</template>


<script>
import {mapMutations, mapGetters} from 'vuex'
import moment from 'moment'
import api from '@/plugins/api'

export default {
  components: {},
  computed: {
  },
  data() {
    return {
      matrix: {},
      settings: [],
      assoc_settings: {},
      loaded: false,
      saved_indicator: false,
      error_message: null,
      show_messages: false,
      error_seved: false,
    }
  },
  mounted() {
    this.fetch()
  },
  watch: {
    columns: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
    settings: {
      handler() {
        this.settingsConvertToMatrix()
      },
      deep: true,
    },
  },
  methods: {
    matrixConvertToSettings() {
      let self = this
      self.matrix.settings.forEach(function(element, index) {
        self.settings[index] = element.enabled
        self.assoc_settings[element.categoryId + '-' + element.targetId] = index
      });
    },
    settingsConvertToMatrix() {
      let self = this
      self.matrix.settings.forEach(function(element, index) {
        self.matrix.settings[self.assoc_settings[element.categoryId + '-' + element.targetId]].enabled = self.settings[self.assoc_settings[element.categoryId + '-' + element.targetId]]
      });
    },
    fetch() {
      let self = this
      self.loaded = false
      api.get('/v2/users/notification-settings', {}).then(responce => {
        self.matrix = responce.data
        self.matrixConvertToSettings()
        self.loaded = true
      }).catch(reason => {
        self.error_message = reason.response.data.error.message
        self.loaded = true
      })
    },
    save() {
      let self = this
      self.saved_indicator = true
      api.post('/v2/users/notification-settings', {settings: self.matrix.settings}).then(responce => {
        self.saved_indicator = false
        self.show_messages = true
        self.error_seved = false
      }).catch(reason => {
        if (reason.response.data && reason.response.data.error && reason.response.data.error.message) {
          self.error_message = reason.response.data.error.message
        }
        else {
          self.error_message = true
        }
        self.show_messages = true
        self.error_seved = true
        self.saved_indicator = false
      })
    }
  }
}
</script>

<style>

</style>